<template>
  <div class="body">
    <div class="content">
      <div class="title">
        <span>联系方式</span>
      </div>
      <div style="margin-top: 50px"></div>
      <div class="list">
        <el-form size="mini">
          <el-form-item label="网站名称">
            <el-input v-model="logo.title" style="width: 299px" size="medium" placeholder="网站名称"></el-input>
          </el-form-item>
          <el-form-item label="网站描述">
            <el-input v-model="logo.description" style="width: 500px" size="medium" placeholder="网站描述"></el-input>
          </el-form-item>
          <el-form-item label="网站关键字">
            <el-input v-model="logo.keywords" style="width: 500px" size="medium" placeholder="网站关键字,号隔开"></el-input>
          </el-form-item>
          <el-form-item label="网站LOGO（PC）">
            <Imgpond v-model="logo.logo" :count="1" />
          </el-form-item>
          <el-form-item label="网站LOGO（h5）">
            <Imgpond v-model="logo.logoWhite" :count="1" />
          </el-form-item>
          <br />
          <br />
          <br />
          <el-form-item label="联系电话">
            <el-input v-model="company.tel" style="width: 299px" size="medium" placeholder="联系电话"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="company.email" style="width: 299px" size="medium" placeholder="邮箱"></el-input>
          </el-form-item>
          <el-form-item label="公司地址">
            <el-input v-model="company.address" style="width: 299px" size="medium" placeholder="公司地址"></el-input>
          </el-form-item>
          <el-form-item label="工作时间">
            <el-input v-model="company.time" style="width: 299px" size="medium" placeholder="工作时间 9:00-18:00"></el-input>
          </el-form-item>
          <el-form-item label="地图坐标">
            <el-input v-model="company.map" style="width: 299px" size="medium" placeholder="地图坐标39.935,116.469"></el-input>
          </el-form-item>
          <el-form-item label="咨询信息">
            <el-input v-model="company.text" style="width: 299px" size="medium" placeholder="感谢您对我司品牌的关心与厚爱，全国招募经销商，可咨询 0537-2522017"></el-input>
          </el-form-item>
          <el-form-item label="背景图片">
            <Imgpond v-model="company.bgImg" :count="1" />
          </el-form-item>
          <el-form-item label="按钮一">
            <el-input v-model="button[0].name" style="width: 110px; margin-right: 16px" size="medium" placeholder="按钮一"></el-input>
            <el-input v-model="button[0].url" style="width: 110px" size="medium" placeholder="地址"></el-input>
          </el-form-item>
          <el-form-item label="按钮二">
            <el-input v-model="button[1].name" style="width: 110px; margin-right: 16px" size="medium" placeholder="按钮二"></el-input>
            <el-input v-model="button[1].url" style="width: 110px" size="medium" placeholder="地址"></el-input>
          </el-form-item>
          <el-form-item label="企业优点"></el-form-item>
          <el-form-item label="">
            <el-input v-model="listInfo[0].title" style="width: 110px; margin-right: 16px" size="medium" placeholder="数据一"></el-input>
            <el-input v-model="listInfo[0].text" style="width: 110px" size="medium" placeholder="信息"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="listInfo[1].title" style="width: 110px; margin-right: 16px" size="medium" placeholder="数据二"></el-input>
            <el-input v-model="listInfo[1].text" style="width: 110px" size="medium" placeholder="信息"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="listInfo[2].title" style="width: 110px; margin-right: 16px" size="medium" placeholder="数据三"></el-input>
            <el-input v-model="listInfo[2].text" style="width: 110px" size="medium" placeholder="信息"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="listInfo[3].title" style="width: 110px; margin-right: 16px" size="medium" placeholder="数据四"></el-input>
            <el-input v-model="listInfo[3].text" style="width: 110px" size="medium" placeholder="信息"></el-input>
          </el-form-item>
          <el-form-item label="版权所有">
            <el-input v-model="company.copyright" style="width: 299px" size="medium" placeholder="版权所有"></el-input>
          </el-form-item>
          <el-form-item label="版权所有时间">
            <el-input v-model="company.crTime" style="width: 299px" size="medium" placeholder="时间"></el-input>
          </el-form-item>
          <el-form-item label="备案号">
            <el-input v-model="company.recode" style="width: 299px" size="medium" placeholder="备案号"></el-input>
          </el-form-item>

          <el-form-item label="公众号">
            <Imgpond v-model="company.public" :count="1" />
          </el-form-item>
          <el-form-item label="微博">
            <Imgpond v-model="company.weibo" :count="1" />
          </el-form-item>
          <el-form-item label="右侧图标" style="display: flex">
            <Imgpond v-model="riImg[0].img" :count="1" />
            <el-input v-model="riImg[0].url" style="width: 200px; margin-bottom: 10px" size="medium" placeholder="链接"></el-input>
            <Imgpond v-model="riImg[1].img" :count="1" />
            <el-input v-model="riImg[1].url" style="width: 200px; margin-bottom: 10px" size="medium" placeholder="链接"></el-input>
            <Imgpond v-model="riImg[2].img" :count="1" />
            <el-input v-model="riImg[2].url" style="width: 200px; margin-bottom: 10px" size="medium" placeholder="链接"></el-input>
          </el-form-item>
          <el-form-item label="联系图标" style="display: flex">
            <Imgpond v-model="icons[0].img" :count="1" />
            <el-input v-model="icons[0].url" style="width: 200px; margin-bottom: 10px" size="medium" placeholder="链接"></el-input>
            <Imgpond v-model="icons[1].img" :count="1" />
            <el-input v-model="icons[1].url" style="width: 200px; margin-bottom: 10px" size="medium" placeholder="链接"></el-input>
            <Imgpond v-model="icons[2].img" :count="1" />
            <el-input v-model="icons[2].url" style="width: 200px; margin-bottom: 10px" size="medium" placeholder="链接"></el-input>
          </el-form-item>
          <el-button type="primary" @click="submit" size="medium">保存内容</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import imageUpload from '@/components/image-upload/image-upload'
export default {
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      logo: {
        title: '',
        logo: ''
      },
      company: {
        tel: '',
        email: '',
        address: '',
        time: '',
        map: '',
        text: '',
        copyright: '',
        crTime: '',
        support: '',
        image: '',
        public: '',
        weibo: '',
        recode: '',
        bgImg: ''
      },
      button: [
        {
          name: '',
          url: ''
        },
        {
          name: '',
          url: ''
        }
      ],
      listInfo: [
        { title: '', text: '' },
        { title: '', text: '' },
        { title: '', text: '' },
        { title: '', text: '' }
      ],
      icons: [
        { img: '', url: '' },
        { img: '', url: '' },
        { img: '', url: '' }
      ],
      riImg: [
        { img: '', url: '' },
        { img: '', url: '' },
        { img: '', url: '' }
      ]
    }
  },
  created() {
    this.getText()
  },
  methods: {
    getText() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSettings',
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          if (res) {
            if (res.logo) this.logo = res.logo
            if (res.company) this.company = res.company
            if (res.button) this.button = res.button
            if (res.listInfo) this.listInfo = res.listInfo
            if (res.icons) this.icons = res.icons
            if (res.riImg) this.riImg = res.riImg
          }
        })
    },
    submit() {
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'updateSettings',
          company: this.company,
          logo: this.logo,
          button: this.button,
          listInfo: this.listInfo,
          icons: this.icons,
          riImg: this.riImg,
          project: JSON.parse(this.project)._id
        })
        .then(res => {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
        })
    },
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64)
      if (result) {
        this.company.image = result.url
      } else {
        this.$message.error('图片失败')
        this.company.image = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
  padding: 15px;

  .content {
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    .list {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      padding-left: 12px;
      border-left: 6px solid #6775e1;
    }
  }
}
</style>
